import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logoMalecites.png'


const _hoisted_1 = { class: "barreHeader" }
const _hoisted_2 = { class: "filsAriane" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        class: "logoMalecites",
        to: { name: 'Home' }
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "logo malecites"
          }, null, -1)
        ])),
        _: 1
      }),
      _createVNode(_component_HeaderMenu)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Breadcrumb, { class: "breadcrumbComponent" })
    ])
  ], 64))
}