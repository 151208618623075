import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modification-coupons" }
const _hoisted_2 = {
  key: 0,
  class: "titre"
}
const _hoisted_3 = {
  key: 1,
  class: "titre"
}
const _hoisted_4 = { class: "contenu" }
const _hoisted_5 = { class: "formulaire fond-blanc" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { action: "Modification" }
const _hoisted_9 = { class: "form-nom" }
const _hoisted_10 = { class: "form-courriel" }
const _hoisted_11 = {
  key: 0,
  class: "form-password"
}
const _hoisted_12 = {
  class: "form-role",
  ref: "formRole"
}
const _hoisted_13 = { class: "form-site" }
const _hoisted_14 = { class: "bouton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "distribution-communautaire" }, "DISTRIBUTION COMMUNAUTAIRE", -1)),
    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "ligne-pale" }, null, -1)),
    (!_ctx.creation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[8] || (_cache[8] = [
          _createElementVNode("h1", null, "Modifier un utilisateur", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[9] || (_cache[9] = [
          _createElementVNode("h1", null, "Création d'un utilisateur", -1)
        ]))),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.creation)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_6, "Utilisateur #" + _toDisplayString(_ctx.user.id), 1))
          : (_openBlock(), _createElementBlock("h2", _hoisted_7, "Créer un utilisateur")),
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "ligne-pale" }, null, -1)),
        _createElementVNode("form", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[10] || (_cache[10] = _createElementVNode("label", { for: "nom" }, "Nom Complet", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: "nom",
              placeholder: "Nom",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.name) = $event))
            }, null, 512), [
              [_vModelText, _ctx.user.name]
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { for: "courriel" }, "Courriel", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: "courriel",
              placeholder: "Courriel",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event))
            }, null, 512), [
              [_vModelText, _ctx.user.email]
            ])
          ]),
          (_ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _cache[12] || (_cache[12] = _createElementVNode("label", { for: "password" }, "Mot de passe", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "password",
                  id: "password",
                  placeholder: "Mot de passe",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.password) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.user.password]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            _cache[14] || (_cache[14] = _createElementVNode("label", { for: "role" }, "Rôle", -1)),
            _withDirectives(_createElementVNode("select", {
              name: "role",
              id: "role",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.roleId) = $event))
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("option", { value: "1" }, "Administrateur", -1),
              _createElementVNode("option", { value: "2" }, "Distributeur", -1)
            ]), 512), [
              [_vModelSelect, _ctx.user.roleId]
            ])
          ], 512),
          _createElementVNode("div", _hoisted_13, [
            _cache[15] || (_cache[15] = _createElementVNode("label", { for: "site" }, "Site de distribution", -1)),
            _createVNode(_component_multiselect, {
              modelValue: _ctx.user.distributions,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.distributions) = $event)),
              options: _ctx.adresses,
              label: "displayName",
              multiple: true,
              "track-by": "displayName"
            }, null, 8, ["modelValue", "options"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      (!_ctx.creation)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "button button-primary",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveUser()))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'box-check'],
              class: "box-check-logo"
            }),
            _cache[17] || (_cache[17] = _createTextVNode(" Sauvegarder les modifications "))
          ]))
        : _createCommentVNode("", true),
      (_ctx.creation)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "button button-primary",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.saveUser()))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'box-check'],
              class: "box-check-logo"
            }),
            _cache[18] || (_cache[18] = _createTextVNode(" Créer l'utilisateur "))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.creation)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "button button-primary delete",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.deleteUser()))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'trash-can'],
              class: "box-check-logo"
            }),
            _cache[19] || (_cache[19] = _createTextVNode(" Supprimer l'utilisateur "))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}