import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "componentlist" }
const _hoisted_2 = { class: "componentlist__item" }
const _hoisted_3 = { class: "componentlist__item-layout componentlist__item-spacing" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _component_Tab = _resolveComponent("Tab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", null, "MessageBox", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MessageBox, {
          HeaderMessage: 'Commande envoyée avec succès',
          BodyMessage: 'Merci pour votre commande. Vous recevrez des coupons',
          type: _ctx.boxStyle.Success
        }, null, 8, ["type"]),
        _createVNode(_component_MessageBox, {
          HeaderMessage: 'Commande envoyée avec succès',
          BodyMessage: 'Merci pour votre commande. Vous recevrez des coupons',
          type: _ctx.boxStyle.Info
        }, null, 8, ["type"]),
        _createVNode(_component_MessageBox, {
          HeaderMessage: 'Commande envoyée avec succès',
          BodyMessage: 'Merci pour votre commande. Vous recevrez des coupons',
          type: _ctx.boxStyle.Warning
        }, null, 8, ["type"]),
        _createVNode(_component_MessageBox, {
          HeaderMessage: 'Commande envoyée avec succès',
          BodyMessage: 'Merci pour votre commande. Vous recevrez des coupons',
          type: _ctx.boxStyle.Error
        }, null, 8, ["type"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_Tab)
      ])
    ])
  ]))
}