<template>
  <div
    v-for="perm in availablePermis"
    v-bind:key="perm.id"
    class="content-boite"
  >
    <span>
      <input
        type="checkbox"
        :value="perm.id"
        @change="updateSelectedPermis(perm)"
      />
      <label v-if="locale === 'fr'" class="nom-permis">{{ perm.nom }}</label>
      <label v-else class="nom-permis">{{ perm.nom_en }}</label>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Panier } from '@/types/Panier';
import { PermisDTO } from '@/types/store/DTO/PermisDTO';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
import EventBus from '@/events/EventBus';
import commonMessages from '@/locales/common';
import { integer } from '@vuelidate/validators';
export default defineComponent({
  name: 'Requestbox',
  props: {
    totalPrice: Number,
  },
  data() {
    return {
      panier: {} as Panier,
      allPermis: new Array<PermisDTO>(),
      selectedPermis: new Array<PermisDTO>(),
      commandesPermis: new Array<CommandePermisDTO>(),
      availablePermis: new Array<PermisDTO>(),
      total: this.totalPrice,
      locale: (this.$i18n as any).locale,
    };
  },
  methods: {
    getAllPermis() {
      this.$store
        .dispatch('Permis/getAllPermis')
        .then((data: Array<PermisDTO>) => {
          this.allPermis = data;
          this.getUserPermis();
        });
    },
    getUserPermis() {
      this.$store
        .dispatch('Permis/getUserPermis')
        .then((data: Array<CommandePermisDTO>) => {
          data.forEach((elem) => {
            this.commandesPermis = this.commandesPermis.concat(
              elem.commandes_permis_items
            );
          });
          this.filterAvailablePermis();
        });
    },
    filterAvailablePermis() {
      this.allPermis.forEach((perm) => {
        let value = this.commandesPermis.find((cp) => cp.id === perm.id);
        if (value === undefined) {
          this.availablePermis.push(perm);
        }
      });
    },
    updateSelectedPermis(permis) {
      let obj = this.selectedPermis.find((perm) => perm.id === permis.id);
      if (obj !== undefined) {
        let index = this.selectedPermis.indexOf(obj);
        this.selectedPermis.splice(index, 1);
      } else {
        this.selectedPermis.push(permis);
      }
      this.$emit('cart-change', this.selectedPermis);
    },
    recalculateTotal() {
      this.total = 0;
      this.selectedPermis.forEach((perm) => {
        this.total = this.total + parseInt(perm.prix);
      });
    },
  },
  created() {
    this.getAllPermis();
  },
  i18n: {
    sharedMessages: commonMessages,
  },
});
</script>

<style scoped lang="scss">
.content-boite {
  display: flex;
  .box {
    .btn-supprimer {
      border: none;
      background-color: transparent;
      margin-top: 12px;
      color: $bleuFonce;
    }
  }
  .boite {
    margin-top: 20px;
    display: flex;
    .saumon-icon {
      width: 2em;
    }
    span {
      margin-left: 20px;
      align-self: center;
      display: flex;
      label {
        font-size: 26px;
        color: $bleu;
        align-self: center;
      }
    }
  }
  .b1 {
    margin-right: 50px;
  }
}
.nom-permis {
  margin-left: 5px;
  vertical-align: middle;
  height: 100%;
  margin-bottom: 2.5px;
}
input[type='checkbox'] {
  width: 15px;
  height: 15px;
}
@media only screen and (max-width: $md) {
  .content-boite {
    display: block;
    .b1 {
      margin-left: 0;
    }
  }
}
</style>
