import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-box" }
const _hoisted_2 = { class: "content-box" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "nom-permis"
}
const _hoisted_5 = {
  key: 1,
  class: "nom-permis"
}
const _hoisted_6 = { class: "prix" }
const _hoisted_7 = { class: "footer-box" }
const _hoisted_8 = { class: "prix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", { class: "span-title" }, "Total")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.panier.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panier, (permis) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "permis-row",
                key: permis.id
              }, [
                (_ctx.locale == 'fr')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(permis.nom), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(permis.nom_en), 1)),
                _createElementVNode("label", _hoisted_6, _toDisplayString(permis.prix.toString() + ' $'), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createElementVNode("label", { class: "total-label" }, "Total", -1)),
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.total), 1)
      ])
    ])
  ]))
}