import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, vModelSelect as _vModelSelect, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modification-coupons" }
const _hoisted_2 = {
  key: 0,
  class: "titre"
}
const _hoisted_3 = {
  key: 1,
  class: "titre"
}
const _hoisted_4 = { class: "contenu" }
const _hoisted_5 = { class: "choix-boite fond-blanc" }
const _hoisted_6 = { class: "boites" }
const _hoisted_7 = ["value", "onChange"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "errorMessage" }
const _hoisted_11 = { class: "formulaire fond-blanc" }
const _hoisted_12 = { action: "Modification" }
const _hoisted_13 = {
  key: 0,
  class: "form-no-membre"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "form-nom"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 2,
  class: "form-prenom"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  key: 3,
  class: "form-adresse"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 4,
  class: "form-ville"
}
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  key: 5,
  class: "form-province"
}
const _hoisted_24 = ["value"]
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 6,
  class: "form-code-postal"
}
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 7,
  class: "form-email"
}
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = {
  key: 8,
  class: "form-telephone"
}
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "form-site" }
const _hoisted_35 = ["value"]
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { class: "bouton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[33] || (_cache[33] = _createElementVNode("div", { class: "distribution-communautaire" }, "DISTRIBUTION COMMUNAUTAIRE", -1)),
    _cache[34] || (_cache[34] = _createElementVNode("div", { class: "ligne-pale" }, null, -1)),
    (!_ctx.creation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[15] || (_cache[15] = [
          _createElementVNode("h1", null, "Modifier un coupon", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[16] || (_cache[16] = [
          _createElementVNode("h1", null, "Création d'un coupon", -1)
        ]))),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[17] || (_cache[17] = _createElementVNode("h2", null, "Boites demandées", -1)),
        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "ligne-pale" }, null, -1)),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.produitsDC, (produitDC) => {
            return (_openBlock(), _createElementBlock("div", {
              key: produitDC.id,
              class: "choix-container-item"
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "choix-container-item-checkbox",
                value: produitDC.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCoupon) = $event)),
                onChange: ($event: any) => (_ctx.updateSelectedProducts(produitDC))
              }, null, 40, _hoisted_7), [
                [_vModelCheckbox, _ctx.selectedCoupon]
              ]),
              _createElementVNode("span", null, _toDisplayString(produitDC.nom), 1),
              _createElementVNode("img", {
                src: produitDC.path_icone,
                alt: "logo"
              }, null, 8, _hoisted_8)
            ]))
          }), 128))
        ]),
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.errorMessage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[29] || (_cache[29] = _createElementVNode("h2", null, "Information pour l'envoi", -1)),
        _cache[30] || (_cache[30] = _createElementVNode("div", { class: "ligne-pale" }, null, -1)),
        _createElementVNode("form", _hoisted_12, [
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _cache[19] || (_cache[19] = _createElementVNode("label", { for: "no-membre" }, "Numéro de membre", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "no-membre",
                  placeholder: "12-12345",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.coupon.memberId) = $event)),
                  onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onBlur()))
                }, null, 544), [
                  [_vModelText, _ctx.coupon.memberId]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.memberId.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _cache[20] || (_cache[20] = _createElementVNode("label", { for: "nom" }, "Nom", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "nom",
                  placeholder: "Nom",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.coupon.lastName) = $event)),
                  onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onBlur()))
                }, null, 544), [
                  [_vModelText, _ctx.coupon.lastName]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.lastName.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[21] || (_cache[21] = _createElementVNode("label", { for: "prenom" }, "Prénom", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "prenom",
                  placeholder: "Prénom",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.coupon.firstName) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.coupon.firstName]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.firstName.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _cache[22] || (_cache[22] = _createElementVNode("label", { for: "adresse" }, "Adresse", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "addresse",
                  placeholder: "Adresse",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.coupon.adress) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.coupon.adress]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.adress.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _cache[23] || (_cache[23] = _createElementVNode("label", { for: "ville" }, "Ville", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "ville",
                  placeholder: "Ville",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.coupon.city) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.coupon.city]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.city.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _cache[24] || (_cache[24] = _createElementVNode("label", { for: "Province" }, "Province", -1)),
                _withDirectives(_createElementVNode("select", {
                  name: "Province",
                  id: "Province",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.coupon.province) = $event)),
                  disabled: ""
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provinces, (provinces) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: provinces.abbreviation,
                      key: provinces.abbreviation
                    }, _toDisplayString(provinces.name), 9, _hoisted_24))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.coupon.province]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.province.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _cache[25] || (_cache[25] = _createElementVNode("label", { for: "code-postal" }, "Code postal", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "code-postal",
                  placeholder: "A1A 1A1",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.coupon.postalCode) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.coupon.postalCode]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.postalCode.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _cache[26] || (_cache[26] = _createElementVNode("label", { for: "email" }, "Courriel", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "email",
                  id: "email",
                  placeholder: "Courriel",
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.coupon.email) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.coupon.email]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.email.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true),
                    (error.$validator == 'email')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_30, " courriel incorrect "))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.coupon.created_by_id || _ctx.creation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                _cache[27] || (_cache[27] = _createElementVNode("label", { for: "telephone" }, "Téléphone", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "telephone",
                  placeholder: "000-000-0000",
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.coupon.telephone) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.coupon.telephone],
                  [_directive_maska, '(###) ###-####']
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.telephone.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "errorMessage",
                    key: error.$uid
                  }, [
                    (error.$validator == 'required')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.requiredMessage), 1))
                      : _createCommentVNode("", true),
                    (error.$validator == 'minLength')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_33, " Téléphone incorrect "))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_34, [
            _cache[28] || (_cache[28] = _createElementVNode("label", { for: "site" }, " Site de distribution où vous irez chercher votre commande ", -1)),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.coupon.site_distribution_id) = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adresses, (adress) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: adress.id,
                  value: adress.id
                }, _toDisplayString(_ctx.uppercase(adress.ville)) + " - " + _toDisplayString(adress.lieux), 9, _hoisted_35))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.coupon.site_distribution_id]
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.coupon.site_distribution_id.$errors, (error) => {
              return (_openBlock(), _createElementBlock("p", {
                class: "errorMessage",
                key: error.$uid
              }, [
                (error.$validator == 'required')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.requiredMessage), 1))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_37, [
      _createElementVNode("button", {
        class: "button button-primary",
        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.submitform()))
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'box-check'],
          class: "box-check-logo"
        }),
        _cache[31] || (_cache[31] = _createTextVNode(" Sauvegarder les modifications "))
      ]),
      _createVNode(_component_ConfirmDialog),
      (!_ctx.creation)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "button button-primary delete",
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.deleteCoupon()))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'trash-can'],
              class: "box-check-logo"
            }),
            _cache[32] || (_cache[32] = _createTextVNode(" Supprimer le coupon "))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}