import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container-box" }
const _hoisted_3 = {
  id: "form-assemblee",
  action: ""
}
const _hoisted_4 = { class: "form-assemblee" }
const _hoisted_5 = { class: "input-field small" }
const _hoisted_6 = { class: "input-field small" }
const _hoisted_7 = { class: "input-field small" }
const _hoisted_8 = { class: "input-field small" }
const _hoisted_9 = { class: "input-field small" }
const _hoisted_10 = { class: "input-field small" }
const _hoisted_11 = { class: "input-field small" }
const _hoisted_12 = { class: "input-field small" }
const _hoisted_13 = { class: "input-field small" }
const _hoisted_14 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_15 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_16 = {
  key: 0,
  class: "form-assemblee",
  style: {"margin-top":"10px"}
}
const _hoisted_17 = { class: "input-field small" }
const _hoisted_18 = { class: "input-field small" }
const _hoisted_19 = { class: "input-field small" }
const _hoisted_20 = { class: "input-field small" }
const _hoisted_21 = { class: "input-field small" }
const _hoisted_22 = { class: "input-field small" }
const _hoisted_23 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_24 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  class: "input-field",
  style: {"margin-top":"10px"}
}
const _hoisted_27 = {
  class: "form-assemblee",
  style: {"margin-top":"10px"}
}
const _hoisted_28 = { class: "input-field" }
const _hoisted_29 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_30 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_31 = {
  key: 0,
  class: "form-assemblee",
  style: {"margin-top":"10px"}
}
const _hoisted_32 = { class: "input-field small" }
const _hoisted_33 = { class: "input-field" }
const _hoisted_34 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_35 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_36 = { class: "input-field" }
const _hoisted_37 = {
  class: "bouton",
  style: {"margin-top":"10px"}
}
const _hoisted_38 = { class: "errorMessage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[64] || (_cache[64] = _createElementVNode("label", { class: "titre" }, " Formulaire d'inscription pour l'assemblée générale ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", _hoisted_3, [
        _createVNode(_component_Panel, { header: "Renseignements" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[30] || (_cache[30] = _createElementVNode("label", { for: "input-numMembre" }, "Numéro de membre", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-numMembre",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inscription.memberId) = $event)),
                  onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.getMemberInfo && _ctx.getMemberInfo(...args)))
                }, null, 544), [
                  [_vModelText, _ctx.inscription.memberId]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[31] || (_cache[31] = _createElementVNode("label", { for: "input-nom" }, "Nom", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-nom",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inscription.nom) = $event)),
                  onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.getMemberInfo && _ctx.getMemberInfo(...args)))
                }, null, 544), [
                  [_vModelText, _ctx.inscription.nom]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[32] || (_cache[32] = _createElementVNode("label", { for: "input-prenom" }, "Prénom", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-prenom",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inscription.prenom) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.inscription.prenom]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[33] || (_cache[33] = _createElementVNode("label", { for: "input-adresse" }, "Adresse", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-adresse",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.inscription.adresse) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.inscription.adresse]
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[34] || (_cache[34] = _createElementVNode("label", { for: "input-ville" }, "Ville", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-ville",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inscription.ville) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.inscription.ville]
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[35] || (_cache[35] = _createElementVNode("label", { for: "input-codePostal" }, "Code postal", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-codePostal",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.inscription.codePostal) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.inscription.codePostal]
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[36] || (_cache[36] = _createElementVNode("label", { for: "input-telephone" }, "Téléphone", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-telephone",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.inscription.telephone) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.inscription.telephone]
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _cache[37] || (_cache[37] = _createElementVNode("label", { for: "input-courriel" }, "Courriel", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-courriel",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.inscription.email) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.inscription.email]
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[38] || (_cache[38] = _createElementVNode("label", { for: "input-age" }, "Âge", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  min: "0",
                  step: "1",
                  id: "input-age",
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.inscription.age) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.inscription.age]
                ])
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Panel, { header: "Section 2 - Accompagnateurs" }, {
          default: _withCtx(() => [
            _cache[48] || (_cache[48] = _createElementVNode("label", null, "Serez-vous accompagné à l'Assemblée générale ?", -1)),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_RadioButton, {
                modelValue: _ctx.inscription.accompagnement,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.inscription.accompagnement) = $event)),
                inputId: "accompagnateurs_bool_true",
                name: "accompagnateurs_bool",
                value: "Oui"
              }, null, 8, ["modelValue"]),
              _cache[39] || (_cache[39] = _createElementVNode("label", {
                for: "accompagnateurs_bool_true",
                style: {"margin-left":"10px"}
              }, " Oui ", -1))
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_RadioButton, {
                modelValue: _ctx.inscription.accompagnement,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.inscription.accompagnement) = $event)),
                inputId: "accompagnateurs_bool_false",
                name: "accompagnateurs_bool",
                value: "Non"
              }, null, 8, ["modelValue"]),
              _cache[40] || (_cache[40] = _createElementVNode("label", {
                for: "accompagnateurs_bool_false",
                style: {"margin-left":"10px"}
              }, " Non ", -1))
            ]),
            _createElementVNode("div", null, [
              _cache[47] || (_cache[47] = _createTextVNode(" Inscrire le nom des personnes qui voyageront avec vous et qui participeront à la rencontre. À noter que seuls les membres pourront participer à la l'AGA et seront admis dans la salle. ")),
              (_ctx.inscription.accompagnement == 'Oui')
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _cache[41] || (_cache[41] = _createElementVNode("label", { for: "input-nom-accompagnateur1" }, "Nom et prénom", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-nom-accompagnateur1",
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.inscription.nom_accompagnateur_1) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.inscription.nom_accompagnateur_1]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _cache[42] || (_cache[42] = _createElementVNode("label", { for: "input-numMembre-accompagnateur1" }, " Numéro de membre ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-numMembre-accompagnateur1",
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.inscription.num_membre_accompagnateur_1) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.inscription.num_membre_accompagnateur_1]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[43] || (_cache[43] = _createElementVNode("label", { for: "input-nom-accompagnateur2" }, "Nom et prénom", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-nom-accompagnateur2",
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.inscription.nom_accompagnateur_2) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.inscription.nom_accompagnateur_2]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _cache[44] || (_cache[44] = _createElementVNode("label", { for: "input-numMembre-accompagnateur2" }, " Numéro de membre ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-numMembre-accompagnateur2",
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.inscription.num_membre_accompagnateur_2) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.inscription.num_membre_accompagnateur_2]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[45] || (_cache[45] = _createElementVNode("label", { for: "input-nom-accompagnateur3" }, "Nom et prénom", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-nom-accompagnateur3",
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.inscription.nom_accompagnateur_3) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.inscription.nom_accompagnateur_3]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _cache[46] || (_cache[46] = _createElementVNode("label", { for: "input-numMembre-accompagnateur3" }, " Numéro de membre ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-numMembre-accompagnateur3",
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.inscription.num_membre_accompagnateur_3) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.inscription.num_membre_accompagnateur_3]
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Panel, { header: "Section 3 - Allergies" }, {
          default: _withCtx(() => [
            _cache[52] || (_cache[52] = _createElementVNode("label", null, " Est-ce que vous ou une personne qui vous accompagne a des allergies alimentaires? ", -1)),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_RadioButton, {
                modelValue: _ctx.inscription.allergies,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.inscription.allergies) = $event)),
                inputId: "allergies_bool_true",
                name: "allergies_bool",
                value: "Oui"
              }, null, 8, ["modelValue"]),
              _cache[49] || (_cache[49] = _createElementVNode("label", {
                for: "allergies_bool_true",
                style: {"margin-left":"10px"}
              }, " Oui ", -1))
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_RadioButton, {
                modelValue: _ctx.inscription.allergies,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.inscription.allergies) = $event)),
                inputId: "allergies_bool_false",
                name: "allergies_bool",
                value: "Non"
              }, null, 8, ["modelValue"]),
              _cache[50] || (_cache[50] = _createElementVNode("label", {
                for: "allergies_bool_false",
                style: {"margin-left":"10px"}
              }, " Non ", -1))
            ]),
            (_ctx.inscription.allergies == 'Oui')
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _cache[51] || (_cache[51] = _createTextVNode(" Si oui, qui est cette personne et de quelle(s) allergie(s) souffre-t-elle ? ")),
                  _createElementVNode("div", _hoisted_26, [
                    _withDirectives(_createElementVNode("textarea", {
                      id: "input-allergies",
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.inscription.details_allergies) = $event)),
                      style: {"width":"100%"}
                    }, null, 512), [
                      [_vModelText, _ctx.inscription.details_allergies]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Panel, { header: "Section 4 - Hébergement" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _cache[61] || (_cache[61] = _createTextVNode(" Pour les membres qui habitent à plus de 75 km de l'Hôtel Universel de Rivière-du Loup (source utilisée : GoogleMap), la PNWW offre les frais d'hébergement pour deux nuits (vendredi 7 juin et samedi 8 juin). Des accommodements pour les aînés (membres de plus de 75 ans) sont disponibles. ")),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _cache[55] || (_cache[55] = _createElementVNode("label", { for: "input-hebergement_bool" }, " J'habite à plus de 75 km et j'ai besoin d'hébergement ", -1)),
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(_component_RadioButton, {
                      modelValue: _ctx.inscription.hebergement,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.inscription.hebergement) = $event)),
                      inputId: "hebergement_bool_true",
                      name: "hebergement_bool",
                      value: "Oui"
                    }, null, 8, ["modelValue"]),
                    _cache[53] || (_cache[53] = _createElementVNode("label", {
                      for: "hebergement_bool_true",
                      style: {"margin-left":"10px"}
                    }, " Oui ", -1))
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createVNode(_component_RadioButton, {
                      modelValue: _ctx.inscription.hebergement,
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.inscription.hebergement) = $event)),
                      inputId: "hebergement_bool_false",
                      name: "hebergement_bool",
                      value: "Non"
                    }, null, 8, ["modelValue"]),
                    _cache[54] || (_cache[54] = _createElementVNode("label", {
                      for: "hebergement_bool_false",
                      style: {"margin-left":"10px"}
                    }, " Non ", -1))
                  ])
                ])
              ]),
              (_ctx.inscription.hebergement == 'Oui')
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _cache[56] || (_cache[56] = _createElementVNode("label", { for: "input-hebergement_nb_chambres" }, " Combien de chambres aurez-vous besoin (maximum de quatre personnes par chambre) ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        step: "1",
                        min: "1",
                        id: "input-hebergement_nb_chambres",
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.inscription.nb_chambres) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.inscription.nb_chambres]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _cache[59] || (_cache[59] = _createElementVNode("label", { for: "input-hebergement_besoins_speciaux" }, " Avez-vous des besoins spéciaux pour la chambre? Exemple : personne à mobilité réduite. ", -1)),
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_RadioButton, {
                          modelValue: _ctx.inscription.besoins_speciaux,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.inscription.besoins_speciaux) = $event)),
                          inputId: "hebergement_besoins_speciaux_true",
                          name: "hebergement_besoins_speciaux",
                          value: "Oui"
                        }, null, 8, ["modelValue"]),
                        _cache[57] || (_cache[57] = _createElementVNode("label", {
                          for: "hebergement_besoins_speciaux_true",
                          style: {"margin-left":"10px"}
                        }, " Oui ", -1))
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createVNode(_component_RadioButton, {
                          modelValue: _ctx.inscription.besoins_speciaux,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.inscription.besoins_speciaux) = $event)),
                          inputId: "hebergement_besoins_speciaux_false",
                          name: "hebergement_besoins_speciaux",
                          value: "Non"
                        }, null, 8, ["modelValue"]),
                        _cache[58] || (_cache[58] = _createElementVNode("label", {
                          for: "hebergement_besoins_speciaux_false",
                          style: {"margin-left":"10px"}
                        }, " Non ", -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      _cache[60] || (_cache[60] = _createElementVNode("label", { for: "input-hebergement_besoins_speciaux_description" }, " Si oui, donnez-nous plus de détails ", -1)),
                      _withDirectives(_createElementVNode("textarea", {
                        id: "input-hebergement_besoins_speciaux_description",
                        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.inscription.details_besoins_speciaux) = $event)),
                        style: {"width":"100%"}
                      }, null, 512), [
                        [_vModelText, _ctx.inscription.details_besoins_speciaux]
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("button", {
            class: "button button-primary",
            type: "button",
            onClick: _cache[28] || (_cache[28] = 
//@ts-ignore
(...args) => (_ctx.saveInscription && _ctx.saveInscription(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'floppy-disk'],
              class: "disquette"
            }),
            _cache[62] || (_cache[62] = _createTextVNode(" Soumettre "))
          ]),
          (!_ctx.creation)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "button button-primary delete",
                type: "button",
                onClick: _cache[29] || (_cache[29] = 
//@ts-ignore
(...args) => (_ctx.deleteInscription && _ctx.deleteInscription(...args)))
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fas', 'trash-can'],
                  class: "box-check-logo"
                }),
                _cache[63] || (_cache[63] = _createTextVNode(" Supprimer l'inscription "))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.error), 1)
      ])
    ])
  ]))
}