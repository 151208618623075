import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/img/icon-saumon.svg'


const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "cherch-btn" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _cache[9] || (_cache[9] = _createTextVNode(" Du ")),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.minDate) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkDisabled && _ctx.checkDisabled(...args)))
        }, null, 544), [
          [_vModelText, _ctx.minDate]
        ]),
        _cache[10] || (_cache[10] = _createTextVNode(" Au ")),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.maxDate) = $event)),
          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.checkDisabled && _ctx.checkDisabled(...args)))
        }, null, 544), [
          [_vModelText, _ctx.maxDate]
        ]),
        _createElementVNode("button", {
          class: _normalizeClass(["btn-rapport btn-rigth", { notActivated: _ctx.isDisabled }]),
          disabled: _ctx.isDisabled,
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.getRapportsWithDates && _ctx.getRapportsWithDates(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: [_ctx.ico, 'filter']
          }, null, 8, ["icon"]),
          _cache[8] || (_cache[8] = _createTextVNode(" Filtrer "))
        ], 10, _hoisted_1)
      ]),
      _: 1
    }),
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "Rechercher par nom, téléphone…",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.search) = $event))
          }, null, 512), [
            [_vModelText, _ctx.search]
          ]),
          _createElementVNode("button", {
            class: "recherche",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.filter && _ctx.filter(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: [_ctx.ico, 'magnifying-glass']
            }, null, 8, ["icon"])
          ])
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("p", { class: "result" }, "Résultats", -1)),
        _createVNode(_component_DataTable, {
          value: _ctx.rappots,
          paginator: true,
          rows: 10,
          ref: "dt",
          paginatorTemplate: "CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          rowsPerPageOptions: [10, 20, 50],
          responsiveLayout: "scroll",
          currentPageReportTemplate: "{totalRecords} COMMANDES"
        }, {
          paginatorstart: _withCtx(() => [
            _createElementVNode("button", {
              class: "btn-rapport",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.exportCSV && _ctx.exportCSV(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: [_ctx.ico, 'file-export']
              }, null, 8, ["icon"]),
              _cache[14] || (_cache[14] = _createTextVNode(" Exporter en CSV "))
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, { header: "DATE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.created_at.slice(0, 10)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "NUMÉRO DE REÇU" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.commande.squareReceiptNumber), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "id",
              header: "Nº COMMANDE"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.padLeadingZeros(data.commande_id)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "NOM" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtauser.firstName) + " ", 1),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(data.ssamtauser.lastName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "BOITE" }, {
              body: _withCtx(({ data }) => [
                (data.produit.nom === 'Boîte de poisson')
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: "fish",
                      class: _normalizeClass(["ico", { showIcon: true }])
                    }))
                  : _createCommentVNode("", true),
                (data.produit.nom === 'Boîte de gibier')
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 1,
                      icon: "deer",
                      class: _normalizeClass(["ico gibier", { showIcon: true }])
                    }))
                  : _createCommentVNode("", true),
                (data.produit.nom === 'Boîte de saumon')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 2,
                      class: _normalizeClass(["saumon-icon", { showIcon: true }]),
                      src: _imports_0,
                      alt: "saumon"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "ADRESSE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtauser.address) + " ", 1),
                _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(data.ssamtauser.city) + " ", 1),
                _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(data.ssamtauser.postalCode), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "TÉLÉPHONE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtauser.telephone), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "CELLULAIRE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtauser.mobile), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "COURRIEL" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtauser.email), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "GÉNÉRATION" }, {
              body: _withCtx(({ data }) => [
                (data.commande.created_by_id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Manuelle"))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, "Automatique"))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    })
  ]))
}