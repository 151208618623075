import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "titre" }
const _hoisted_3 = { class: "container-box" }
const _hoisted_4 = {
  id: "form-assemblee",
  action: ""
}
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = {
  class: "link",
  href: "profil"
}
const _hoisted_7 = { class: "form-assemblee" }
const _hoisted_8 = { class: "input-field small" }
const _hoisted_9 = { for: "input-nom" }
const _hoisted_10 = { class: "input-field small" }
const _hoisted_11 = { for: "input-prenom" }
const _hoisted_12 = { class: "input-field small" }
const _hoisted_13 = { for: "input-adresse" }
const _hoisted_14 = { class: "input-field small" }
const _hoisted_15 = { for: "input-ville" }
const _hoisted_16 = { class: "input-field small" }
const _hoisted_17 = { for: "input-codePostal" }
const _hoisted_18 = { class: "input-field small" }
const _hoisted_19 = { for: "input-telephone" }
const _hoisted_20 = { class: "input-field" }
const _hoisted_21 = { for: "input-courriel" }
const _hoisted_22 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_23 = {
  for: "accompagnateurs_bool_true",
  style: {"margin-left":"10px"}
}
const _hoisted_24 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_25 = {
  for: "accompagnateurs_bool_false",
  style: {"margin-left":"10px"}
}
const _hoisted_26 = { class: "error-msg" }
const _hoisted_27 = {
  key: 0,
  class: "form-assemblee",
  style: {"margin-top":"10px"}
}
const _hoisted_28 = { class: "input-field small" }
const _hoisted_29 = { for: "input-nom-accompagnateur1" }
const _hoisted_30 = { class: "error-msg" }
const _hoisted_31 = { class: "input-field small" }
const _hoisted_32 = { for: "input-numMembre-accompagnateur1" }
const _hoisted_33 = { class: "error-msg" }
const _hoisted_34 = { class: "input-field small" }
const _hoisted_35 = { for: "input-nom-accompagnateur2" }
const _hoisted_36 = { class: "input-field small" }
const _hoisted_37 = { for: "input-numMembre-accompagnateur2" }
const _hoisted_38 = { class: "error-msg" }
const _hoisted_39 = { class: "input-field small" }
const _hoisted_40 = { for: "input-nom-accompagnateur3" }
const _hoisted_41 = { class: "input-field small" }
const _hoisted_42 = { for: "input-numMembre-accompagnateur3" }
const _hoisted_43 = { class: "input-errors" }
const _hoisted_44 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_45 = {
  for: "allergies_bool_true",
  style: {"margin-left":"10px"}
}
const _hoisted_46 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_47 = {
  for: "allergies_bool_false",
  style: {"margin-left":"10px"}
}
const _hoisted_48 = { class: "error-msg" }
const _hoisted_49 = { key: 0 }
const _hoisted_50 = {
  class: "input-field",
  style: {"margin-top":"10px"}
}
const _hoisted_51 = { class: "error-msg" }
const _hoisted_52 = {
  class: "form-assemblee",
  style: {"margin-top":"10px"}
}
const _hoisted_53 = { class: "input-field" }
const _hoisted_54 = { for: "input-hebergement_bool" }
const _hoisted_55 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_56 = {
  for: "hebergement_bool_true",
  style: {"margin-left":"10px"}
}
const _hoisted_57 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_58 = {
  for: "hebergement_bool_false",
  style: {"margin-left":"10px"}
}
const _hoisted_59 = { class: "error-msg" }
const _hoisted_60 = {
  key: 0,
  class: "form-assemblee",
  style: {"margin-top":"10px"}
}
const _hoisted_61 = { class: "input-field small" }
const _hoisted_62 = { for: "input-hebergement_nb_chambres" }
const _hoisted_63 = { class: "error-msg" }
const _hoisted_64 = { class: "input-field" }
const _hoisted_65 = { for: "input-hebergement_besoins_speciaux" }
const _hoisted_66 = { style: {"display":"inline","margin":"0 10px"} }
const _hoisted_67 = {
  for: "hebergement_besoins_speciaux_true",
  style: {"margin-left":"10px"}
}
const _hoisted_68 = { style: {"display":"inline","margin":"0"} }
const _hoisted_69 = {
  for: "hebergement_besoins_speciaux_false",
  style: {"margin-left":"10px"}
}
const _hoisted_70 = { class: "error-msg" }
const _hoisted_71 = {
  key: 0,
  class: "input-field"
}
const _hoisted_72 = { for: "input-hebergement_besoins_speciaux_description" }
const _hoisted_73 = { class: "error-msg" }
const _hoisted_74 = {
  class: "input-field",
  style: {"margin-top":"10px"}
}
const _hoisted_75 = {
  key: 0,
  class: "errorMessage",
  style: {"text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('titrePage')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("form", _hoisted_4, [
        _createVNode(_component_Panel, {
          header: 'Section 1 - ' + _ctx.$t('renseignements')
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.$t('consigneValidite')), 1),
              _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t('votreProfil')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('nom')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-nom",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nom) = $event)),
                  readonly: "",
                  disabled: "true"
                }, null, 512), [
                  [_vModelText, _ctx.nom]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.nom.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "input-errors",
                    key: error.$uid
                  }, _cache[25] || (_cache[25] = [
                    _createElementVNode("div", { class: "error-msg" }, "Le champ Nom est requis.", -1)
                  ])))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('prenom')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-prenom",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.prenom) = $event)),
                  readonly: "",
                  disabled: "true"
                }, null, 512), [
                  [_vModelText, _ctx.prenom]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.prenom.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "input-errors",
                    key: error.$uid
                  }, _cache[26] || (_cache[26] = [
                    _createElementVNode("div", { class: "error-msg" }, "Le champ Prénom est requis.", -1)
                  ])))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('adresse')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-adresse",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.adresse) = $event)),
                  readonly: "",
                  disabled: "true"
                }, null, 512), [
                  [_vModelText, _ctx.adresse]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.adresse.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "input-errors",
                    key: error.$uid
                  }, _cache[27] || (_cache[27] = [
                    _createElementVNode("div", { class: "error-msg" }, "Le champ Adresse est requis.", -1)
                  ])))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('ville')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-ville",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ville) = $event)),
                  readonly: "",
                  disabled: "true"
                }, null, 512), [
                  [_vModelText, _ctx.ville]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.ville.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "input-errors",
                    key: error.$uid
                  }, _cache[28] || (_cache[28] = [
                    _createElementVNode("div", { class: "error-msg" }, "Le champ Ville est requis.", -1)
                  ])))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('codePostal')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-codePostal",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.codePostal) = $event)),
                  readonly: "",
                  disabled: "true"
                }, null, 512), [
                  [_vModelText, _ctx.codePostal]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.codePostal.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "input-errors",
                    key: error.$uid
                  }, _cache[29] || (_cache[29] = [
                    _createElementVNode("div", { class: "error-msg" }, "Le champ Code postal est requis.", -1)
                  ])))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('telephone')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-telephone",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.numeroTelephone) = $event)),
                  readonly: "",
                  disabled: "true"
                }, null, 512), [
                  [_vModelText, _ctx.numeroTelephone]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.numeroTelephone.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "input-errors",
                    key: error.$uid
                  }, _cache[30] || (_cache[30] = [
                    _createElementVNode("div", { class: "error-msg" }, "Le champ Téléphone est requis.", -1)
                  ])))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('email')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "input-courriel",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email) = $event)),
                  readonly: "",
                  disabled: "true"
                }, null, 512), [
                  [_vModelText, _ctx.email]
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.email.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "input-errors",
                    key: error.$uid
                  }, _cache[31] || (_cache[31] = [
                    _createElementVNode("div", { class: "error-msg" }, "Le champ Courriel est requis.", -1)
                  ])))
                }), 128))
              ])
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Panel, {
          header: 'Section 2 - ' + _ctx.$t('accompagnateurs')
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('boolAccompagnementAssemblee')), 1),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_RadioButton, {
                modelValue: this.user.accompagnement,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.user.accompagnement) = $event)),
                inputId: "accompagnateurs_bool_true",
                name: "accompagnateurs_bool",
                value: "Oui"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('oui')), 1)
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_RadioButton, {
                modelValue: this.user.accompagnement,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.user.accompagnement) = $event)),
                inputId: "accompagnateurs_bool_false",
                name: "accompagnateurs_bool",
                value: "Non",
                invalid: 
                _ctx.v$.user.accompagnement.$invalid && _ctx.v$.user.accompagnement.$dirty
              
              }, null, 8, ["modelValue", "invalid"]),
              _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('non')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.accompagnement.$errors, (error) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "input-errors",
                key: error.$uid,
                style: {"display":"inline","margin":"0 10px"}
              }, [
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('erreur_accompagnement')), 1)
              ]))
            }), 128)),
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('accompagnementDescription')) + " ", 1),
              (this.user.accompagnement == 'Oui')
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('nomPrenom')), 1),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-nom-accompagnateur1",
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.user.nom_accompagnateur_1) = $event))
                      }, null, 512), [
                        [_vModelText, this.user.nom_accompagnateur_1]
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.nom_accompagnateur_1.$errors, (error) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "input-errors",
                          key: error.$uid
                        }, [
                          _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('erreur_nom_accompagnateur_1')), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('numMembre')), 1),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-numMembre-accompagnateur1",
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((this.user.num_membre_accompagnateur_1) = $event))
                      }, null, 512), [
                        [_vModelText, this.user.num_membre_accompagnateur_1]
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.num_membre_accompagnateur_1.$errors, (error) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "input-errors",
                          key: error.$uid
                        }, [
                          _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('erreur_num_membre_accompagnateur_1')), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('nomPrenom')), 1),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-nom-accompagnateur2",
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((this.user.nom_accompagnateur_2) = $event))
                      }, null, 512), [
                        [_vModelText, this.user.nom_accompagnateur_2]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t('numMembre')), 1),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-numMembre-accompagnateur2",
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((this.user.num_membre_accompagnateur_2) = $event))
                      }, null, 512), [
                        [_vModelText, this.user.num_membre_accompagnateur_2]
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.num_membre_accompagnateur_2.$errors, (error) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "input-errors",
                          key: error.$uid
                        }, [
                          _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t('erreur_num_membre_accompagnateur_2')), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('nomPrenom')), 1),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-nom-accompagnateur3",
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((this.user.nom_accompagnateur_3) = $event))
                      }, null, 512), [
                        [_vModelText, this.user.nom_accompagnateur_3]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('numMembre')), 1),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "input-numMembre-accompagnateur3",
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((this.user.num_membre_accompagnateur_3) = $event))
                      }, null, 512), [
                        [_vModelText, this.user.num_membre_accompagnateur_3]
                      ]),
                      _createElementVNode("div", _hoisted_43, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.num_membre_accompagnateur_3.$errors, (error) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "error-msg",
                            key: error.$uid
                          }, _toDisplayString(_ctx.$t('erreur_num_membre_accompagnateur_3')), 1))
                        }), 128))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Panel, {
          header: 'Section 3 - ' + _ctx.$t('allergies')
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('boolAllergies')), 1),
            _createElementVNode("div", _hoisted_44, [
              _createVNode(_component_RadioButton, {
                modelValue: this.user.allergies,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((this.user.allergies) = $event)),
                inputId: "allergies_bool_true",
                name: "allergies_bool",
                value: "Oui"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t('oui')), 1)
            ]),
            _createElementVNode("div", _hoisted_46, [
              _createVNode(_component_RadioButton, {
                modelValue: this.user.allergies,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((this.user.allergies) = $event)),
                inputId: "allergies_bool_false",
                name: "allergies_bool",
                value: "Non"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_47, _toDisplayString(_ctx.$t('non')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.allergies.$errors, (error) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "input-errors",
                key: error.$uid,
                style: {"display":"inline","margin":"0 10px"}
              }, [
                _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t('erreur_allergies')), 1)
              ]))
            }), 128)),
            (this.user.allergies == 'Oui')
              ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                  _createTextVNode(_toDisplayString(_ctx.$t('allergiesDescription')) + " ", 1),
                  _createElementVNode("div", _hoisted_50, [
                    _withDirectives(_createElementVNode("textarea", {
                      id: "input-allergies",
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((this.user.details_allergies) = $event)),
                      style: {"width":"100%"}
                    }, null, 512), [
                      [_vModelText, this.user.details_allergies]
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.details_allergies.$errors, (error) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "input-errors",
                        key: error.$uid,
                        style: {"display":"inline","margin":"0 10px"}
                      }, [
                        _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.$t('erreur_details_allergies')), 1)
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Panel, {
          header: 'Section 4 - ' + _ctx.$t('hebergement')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('descriptionHebergement')) + " ", 1),
              _createElementVNode("div", _hoisted_52, [
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("label", _hoisted_54, _toDisplayString(_ctx.$t('boolHebergement')), 1),
                  _createElementVNode("div", _hoisted_55, [
                    _createVNode(_component_RadioButton, {
                      modelValue: this.user.hebergement,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((this.user.hebergement) = $event)),
                      inputId: "hebergement_bool_true",
                      name: "hebergement_bool",
                      value: "Oui"
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("label", _hoisted_56, _toDisplayString(_ctx.$t('oui')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_57, [
                    _createVNode(_component_RadioButton, {
                      modelValue: this.user.hebergement,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((this.user.hebergement) = $event)),
                      inputId: "hebergement_bool_false",
                      name: "hebergement_bool",
                      value: "Non"
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("label", _hoisted_58, _toDisplayString(_ctx.$t('non')), 1)
                  ])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.hebergement.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "input-errors",
                    key: error.$uid,
                    style: {"display":"inline","margin":"0 10px"}
                  }, [
                    _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t('erreur_hebergement')), 1)
                  ]))
                }), 128))
              ]),
              (this.user.hebergement == 'Oui')
                ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                    _createElementVNode("div", _hoisted_61, [
                      _createElementVNode("label", _hoisted_62, _toDisplayString(_ctx.$t('nbChambres')), 1),
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        step: "1",
                        min: "1",
                        id: "input-hebergement_nb_chambres",
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((this.user.nb_chambres) = $event))
                      }, null, 512), [
                        [_vModelText, this.user.nb_chambres]
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.nb_chambres.$errors, (error) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "input-errors",
                          key: error.$uid,
                          style: {"display":"inline","margin":"0 10px"}
                        }, [
                          _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t('erreur_nb_chambres')), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_64, [
                      _createElementVNode("label", _hoisted_65, _toDisplayString(_ctx.$t('besoinsSpeciaux')), 1),
                      _createElementVNode("div", _hoisted_66, [
                        _createVNode(_component_RadioButton, {
                          modelValue: this.user.besoins_speciaux,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((this.user.besoins_speciaux) = $event)),
                          inputId: "hebergement_besoins_speciaux_true",
                          name: "hebergement_besoins_speciaux",
                          value: "Oui"
                        }, null, 8, ["modelValue"]),
                        _createElementVNode("label", _hoisted_67, _toDisplayString(_ctx.$t('oui')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_68, [
                        _createVNode(_component_RadioButton, {
                          modelValue: this.user.besoins_speciaux,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((this.user.besoins_speciaux) = $event)),
                          inputId: "hebergement_besoins_speciaux_false",
                          name: "hebergement_besoins_speciaux",
                          value: "Non"
                        }, null, 8, ["modelValue"]),
                        _createElementVNode("label", _hoisted_69, _toDisplayString(_ctx.$t('non')), 1)
                      ])
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.besoins_speciaux.$errors, (error) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "input-errors",
                        key: error.$uid,
                        style: {"display":"inline","margin":"0"}
                      }, [
                        _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.$t('erreur_besoins_speciaux')), 1)
                      ]))
                    }), 128)),
                    (_ctx.user.besoins_speciaux == 'Oui')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
                          _createElementVNode("label", _hoisted_72, _toDisplayString(_ctx.$t('detailsBesoinsSpeciaux')), 1),
                          _withDirectives(_createElementVNode("textarea", {
                            id: "input-hebergement_besoins_speciaux_description",
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((this.user.details_besoins_speciaux) = $event)),
                            style: {"width":"100%"}
                          }, null, 512), [
                            [_vModelText, this.user.details_besoins_speciaux]
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.user.details_besoins_speciaux.$errors, (error) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "input-errors",
                              key: error.$uid,
                              style: {"display":"inline","margin":"0 10px"}
                            }, [
                              _createElementVNode("div", _hoisted_73, _toDisplayString(_ctx.$t('erreur_details_besoins_speciaux')), 1)
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createElementVNode("div", _hoisted_74, [
          _createElementVNode("button", {
            class: "button button-primary",
            type: "button",
            onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'floppy-disk'],
              class: "disquette"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('btnInscription')), 1)
          ])
        ]),
        (_ctx.error.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_75, _toDisplayString(_ctx.$t('erreurInscription')), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}