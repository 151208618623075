import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/img/icon-saumon.svg'


const _hoisted_1 = ["value"]
const _hoisted_2 = { class: "cherch-btn" }
const _hoisted_3 = { class: "lieu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _cache[12] || (_cache[12] = _createElementVNode("p", { class: "sites" }, "Sites de distribution", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.site) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.filterDatatable && _ctx.filterDatatable(...args))),
          placeholder: "Tous"
        }, [
          _cache[6] || (_cache[6] = _createElementVNode("option", {
            selected: "",
            value: "-1"
          }, "Tous", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adresses, (adress) => {
            return (_openBlock(), _createElementBlock("option", {
              key: adress.ville,
              value: adress.id
            }, _toDisplayString(_ctx.uppercase(adress.ville)) + " - " + _toDisplayString(adress.lieux), 9, _hoisted_1))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.site]
        ]),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "Rechercher par nom, téléphone…",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event))
          }, null, 512), [
            [_vModelText, _ctx.search]
          ]),
          _createElementVNode("button", {
            class: "recherche",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.filter && _ctx.filter(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: [_ctx.ico, 'magnifying-glass']
            }, null, 8, ["icon"])
          ])
        ]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.nomSite), 1),
        _createVNode(_component_DataTable, {
          value: _ctx.rappots,
          paginator: true,
          rows: 10,
          ref: "dt",
          paginatorTemplate: "CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          rowsPerPageOptions: [10, 20, 50],
          responsiveLayout: "scroll",
          currentPageReportTemplate: "{totalRecords} COMMANDES"
        }, {
          paginatorstart: _withCtx(() => [
            _createElementVNode("button", {
              class: "btn-rapport",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.exportCSV && _ctx.exportCSV(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: [_ctx.ico, 'file-export']
              }, null, 8, ["icon"]),
              _cache[10] || (_cache[10] = _createTextVNode(" Exporter en CSV "))
            ]),
            _createElementVNode("button", {
              class: "btn-rapport left-margin",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.exportPDF && _ctx.exportPDF(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: [_ctx.ico, 'file-export']
              }, null, 8, ["icon"]),
              _cache[11] || (_cache[11] = _createTextVNode(" Exporter en PDF "))
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, { header: "DATE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.dateParserLong(data.created_at)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "id",
              header: "Nº COMMANDE"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.padLeadingZeros(data.pivot.commande_id)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "NOM" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtaUser.firstName) + " ", 1),
                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(data.ssamtaUser.lastName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "BOITE" }, {
              body: _withCtx(({ data }) => [
                (data.type === 'poisson')
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: "fish",
                      class: _normalizeClass(["ico", { showIcon: data.path_icone }])
                    }, null, 8, ["class"]))
                  : (data.type === 'gibier')
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        icon: "deer",
                        class: _normalizeClass(["ico gibier", { showIcon: data.path_icone }])
                      }, null, 8, ["class"]))
                    : (data.type === 'saumon')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 2,
                          class: _normalizeClass(["saumon-icon", { showIcon: data.path_icone }]),
                          src: _imports_0,
                          alt: "saumon"
                        }, null, 2))
                      : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "ADRESSE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtaUser.address) + " ", 1),
                _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(data.ssamtaUser.city) + " ", 1),
                _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(data.ssamtaUser.postalCode), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "TÉLÉPHONE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtaUser.telephone), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "CELLULAIRE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtaUser.mobile), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "COURRIEL" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtaUser.email), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    })
  ]))
}