import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-login" }
const _hoisted_2 = { class: "box-login" }
const _hoisted_3 = { class: "titre" }
const _hoisted_4 = { class: "sous-titre" }
const _hoisted_5 = { class: "input-container" }
const _hoisted_6 = {
  for: "Name",
  class: "form-label"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "input-container" }
const _hoisted_10 = {
  for: "dateNaisse",
  class: "form-label"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "input-container" }
const _hoisted_13 = {
  for: "noMembre",
  class: "form-label"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "input-container" }
const _hoisted_16 = {
  key: 0,
  class: "errorcnx"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('bienvenue')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('loginInfo')), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('nomFamille')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "Name",
          class: "form-input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lastName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.lastName]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.lastName.$errors, (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "errorMessage",
            key: error.$uid
          }, [
            (error.$validator == 'required')
              ? (_openBlock(), _createElementBlock("label", _hoisted_7, _toDisplayString(_ctx.requiredMessage), 1))
              : _createCommentVNode("", true),
            (error.$validator == 'minLength')
              ? (_openBlock(), _createElementBlock("label", _hoisted_8, " Ce champ doit être au moins " + _toDisplayString(error.$params.min) + " caractères. ", 1))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('dateNaissance')), 1),
        _createVNode(_component_Datepicker, {
          id: "dateNaisse",
          modelValue: _ctx.birthDate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.birthDate) = $event)),
          enableTimePicker: false,
          flow: _ctx.flow,
          autoApply: "",
          yearRange: [1900, 2025],
          locale: "fr",
          format: "yyyy-MM-dd",
          "value-format": "yyyy-MM-dd"
        }, null, 8, ["modelValue", "flow"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.birthDate.$errors, (error) => {
          return (_openBlock(), _createElementBlock("p", {
            class: "errorMessage",
            key: error.$uid
          }, [
            (error.$validator == 'required')
              ? (_openBlock(), _createElementBlock("label", _hoisted_11, _toDisplayString(_ctx.requiredMessage), 1))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('numMembre')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "noMembre",
          class: "form-input",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.bandNumber) = $event))
        }, null, 512), [
          [_vModelText, _ctx.bandNumber]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.bandNumber.$errors, (error) => {
          return (_openBlock(), _createElementBlock("p", {
            class: "errorMessage",
            key: error.$uid
          }, [
            (error.$validator == 'required')
              ? (_openBlock(), _createElementBlock("label", _hoisted_14, _toDisplayString(_ctx.requiredMessage), 1))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", {
          class: "btn-form",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitform && _ctx.submitform(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'lock'],
            class: "ico-form"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('btnConnexion')), 1)
        ])
      ]),
      (_ctx.errorConnection)
        ? (_openBlock(), _createElementBlock("label", _hoisted_16, "Les informations entrées sont incorrectes"))
        : _createCommentVNode("", true)
    ])
  ]))
}