import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '../assets/img/icon-saumon-noir.svg'


const _hoisted_1 = { class: "page-coupons" }
const _hoisted_2 = { class: "titre" }
const _hoisted_3 = { class: "contenu" }
const _hoisted_4 = { class: "texte" }
const _hoisted_5 = { class: "choix" }
const _hoisted_6 = { class: "checkboxes couponsBox" }
const _hoisted_7 = { class: "bottom-margin" }
const _hoisted_8 = {
  key: 2,
  class: "saumon-icon",
  src: _imports_0,
  alt: "saumon"
}
const _hoisted_9 = { class: "bouton-telecharger" }
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 0,
  class: "no-coupons"
}
const _hoisted_12 = {
  key: 0,
  class: "container-site"
}
const _hoisted_13 = { class: "form-site" }
const _hoisted_14 = { for: "site" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('mesCoupons')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('consigneCoupons')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.produits, (produit) => {
              return (_openBlock(), _createElementBlock("div", {
                key: produit.id
              }, [
                _createElementVNode("span", _hoisted_7, [
                  _createTextVNode("Coupon de " + _toDisplayString(produit.nom.toLowerCase()) + " ", 1),
                  (produit.type === 'poisson')
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: ['far', 'fish'],
                        class: "fish-icon"
                      }))
                    : (produit.type === 'gibier')
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 1,
                          icon: ['fal', 'deer'],
                          class: "steak-icon"
                        }))
                      : (produit.type === 'saumon')
                        ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("a", {
                    class: "button",
                    href: _ctx.getUrlCoupon(produit),
                    download: "couponPoisson.pdf"
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['fas', 'download'],
                      class: "download-icon"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('telecharger')), 1)
                  ], 8, _hoisted_10)
                ])
              ]))
            }), 128)),
            (_ctx.produits.length < 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('aucunCoupons')), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    (_ctx.produits.length > 0 && _ctx.isBeforeDateLimite)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('siteDistribution')), 1),
            _createVNode(_component_Dropdown, {
              options: _ctx.sitesDistribution,
              style: {"width":"100%"},
              modelValue: _ctx.selectedSite,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSite) = $event)),
              optionLabel: "label",
              optionValue: "value",
              filter: ""
            }, null, 8, ["options", "modelValue"]),
            _createVNode(_component_Button, {
              class: "button",
              onClick: _ctx.updateSiteDistribution
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('boutons.sauvegarder')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Toast)
  ]))
}