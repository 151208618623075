import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RapportSd = _resolveComponent("RapportSd")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("header", null, [
      _createElementVNode("h3", { class: "titre-page" }, "DISTRIBUTION COMMUNAUTAIRE")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("label", { class: "titre" }, " Rapport - Sites de distribution ", -1)),
      _createVNode(_component_RapportSd)
    ])
  ], 64))
}