<template>
  <div class="contenu">
    <div class="titre">
      <h1>{{ $t('mesPermis') }}</h1>
    </div>
    <Card>
      <template v-slot:content>
        <DataTable
          :value="permis"
          :paginator="true"
          :rows="10"
          ref="dt"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          currentPageReportTemplate="{totalRecords} PERMIS"
        >
          <Column :header="this.$t('permis')">
            <template #body="data">
              <div v-if="locale == 'fr'">{{ data.data.nom }}</div>
              <div v-else>{{ data.data.nom_en }}</div>
            </template>
          </Column>
          <Column :header="this.$t('possede')">
            <template #body="data">
              <div
                v-if="commandesPermis.some((perm) => perm.id === data.data.id)"
                class="success"
              >
                <font-awesome-icon
                  :icon="['fal', 'circle-check']"
                  class="ico-distribuee success"
                />
              </div>
              <div v-else cdlass="error">
                <font-awesome-icon
                  :icon="['fal', 'circle-xmark']"
                  class="ico-distribuee error"
                />
              </div>
            </template>
          </Column>
          <Column :header="this.$t('date_expiration')">
            <template #body="data">
              <div
                v-if="commandesPermis.some((perm) => perm.id === data.data.id)"
              >
                {{
                  commandesPermis.find((perm) => perm.id === data.data.id).pivot
                    .date_expiration == null
                    ? ''
                    : commandesPermis
                        .find((perm) => perm.id === data.data.id)
                        .pivot.date_expiration.slice(0, 10)
                }}
              </div>
            </template>
          </Column>
          <template #paginatorstart>
            <router-link :to="{ name: 'CommandePermis' }">
              <button class="btn-rapport">
                <font-awesome-icon :icon="['far', 'file-export']" />
                {{ $t('acheter_permis') }}
              </button>
            </router-link>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>
<script lang="ts">
import { PermisDTO } from '@/types/store/DTO/PermisDTO';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Card from 'primevue/card';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'Permis',
  components: {
    DataTable,
    Column,
    Card,
  },
  data() {
    return {
      permis: new Array<PermisDTO>(),
      commandesPermis: new Array<CommandePermisDTO>(),
      locale: (this.$i18n as any).locale,
    };
  },
  methods: {
    getAllPermis() {
      this.$store
        .dispatch('Permis/getAllPermis')
        .then((data: Array<PermisDTO>) => {
          this.permis = data;
        });
    },
    getUserPermis() {
      this.$store
        .dispatch('Permis/getUserPermis')
        .then((data: Array<CommandePermisDTO>) => {
          data.forEach((elem) => {
            this.commandesPermis = this.commandesPermis.concat(
              elem.commandes_permis_items
            );
          });
        });
    },
  },
  created() {
    this.getAllPermis();
    this.getUserPermis();
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        permis: 'Licence',
        possede: 'Owned',
        date_expiration: 'Renewal date',
        acheter_permis: 'Buy a license',
      },
      fr: {
        permis: 'Permis',
        possede: 'Possédé',
        date_expiration: 'Date de renouvellement',
        acheter_permis: 'Acheter un permis',
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.titre {
  text-align: center;
  margin: 1.5%;
}
.saumon-icon {
  width: 2em;
}
.contenu {
  margin: 5%;
}
.recherche-et-bouton-cree {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.checkboxes {
  display: inline-flex;
  width: 100%;
}
.texte {
  width: 100%;
  margin-left: 1%;
  margin-top: 1%;
}
.button {
  background-color: $bleu;
  color: $white;
  border: 1px solid $bleuCiel;
  padding: 1% 1.7% 1% 1.7%;
  margin-top: 1%;
}
.download-icon {
  color: $white;
  width: 20px;
  height: 21px;
}
input {
  transform: scale(1.5);
  margin-right: 3%;
  border-radius: 3px;
  margin-left: 0.5%;
  accent-color: $bleuFonce;
}
label {
  font-family: $police;
  color: $textRapport;
  font-weight: 400;
}
::v-deep(th) {
  background: none !important;
  color: $bleuFonce !important;
  font-weight: 800 !important;
  font-size: 16px !important;
}
::v-deep(td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $textRapport !important;
}
::v-deep(.p-paginator-current) {
  color: $bleuFonce !important;
  font-weight: 500 !important;
}
::v-deep(.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even)) {
  background: #f8f8f8 !important;
}
::v-deep(.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight) {
  background: #e3f2fd !important;
}
.button a {
  color: #fff !important;
}

.btn-rapport {
  border: 1px solid $grisFonce;
  background-color: $bleuFonce;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  color: #fff;
  font-weight: 500;
  .ico {
    margin-right: 5px;
  }
}
.error {
  color: $rougeFonce;
  height: 40px;
}
.success {
  color: $vert;
  height: 40px;
}
</style>
