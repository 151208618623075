import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "contenu" }
const _hoisted_2 = { class: "recherche-et-bouton-cree" }
const _hoisted_3 = { class: "cherch-btn" }
const _hoisted_4 = { class: "create-user" }
const _hoisted_5 = { class: "button-primary button" }
const _hoisted_6 = {
  key: 0,
  class: "button button-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("header", null, [
      _createElementVNode("h3", { class: "titre-page" }, "DISTRIBUTION COMMUNAUTAIRE")
    ], -1)),
    _cache[7] || (_cache[7] = _createElementVNode("label", { class: "titre" }, " LISTE DES UTILISATEURS ", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "liste-user" }, "Liste des utilisateurs", -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "Rechercher par nom, téléphone…",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
              }, null, 512), [
                [_vModelText, _ctx.search]
              ]),
              _createElementVNode("button", {
                class: "recherche",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter()))
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: [_ctx.ico, 'magnifying-glass']
                }, null, 8, ["icon"])
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createVNode(_component_router_link, { to: {
                  name: 'ModificationUtilisateur',
                } }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Créer un utilisateur ")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _createVNode(_component_DataTable, {
            selection: _ctx.selectedUser,
            "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedUser) = $event)),
            dataKey: "id",
            selectionMode: "single",
            autoLayout: true,
            value: _ctx.userList,
            paginator: true,
            rows: 10,
            ref: "dt",
            stripedRows: "",
            paginatorTemplate: "CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
            rowsPerPageOptions: [10, 20, 50],
            responsiveLayout: "scroll",
            currentPageReportTemplate: "{totalRecords} UTILISATEURS"
          }, {
            paginatorstart: _withCtx(() => [
              ('id' in _ctx.selectedUser)
                ? (_openBlock(), _createElementBlock("button", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: {
                  name: 'ModificationUtilisateur',
                  params: { userId: _ctx.selectedUser.id },
                }
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Modifier ")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "created_at",
                header: "DATE DE CRÉATION",
                sortable: true
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.dateParserLong(data.created_at)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "id",
                header: "ID"
              }),
              _createVNode(_component_Column, {
                field: "firstName",
                header: "NOM"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.getFirstName(data.name)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "lastName",
                header: "PRENOM"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.getLastName(data.name)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "email",
                header: "EMAIL"
              }),
              _createVNode(_component_Column, {
                field: "role.role",
                header: "RÔLE"
              })
            ]),
            _: 1
          }, 8, ["selection", "value"])
        ]),
        _: 1
      })
    ])
  ], 64))
}