import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-box" }
const _hoisted_2 = { class: "content-box" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "footer-box" }
const _hoisted_7 = { class: "prix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "header-box" }, [
      _createElementVNode("span", { class: "span-title" }, "Total")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.panier.poisson)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.$t('boite.poisson')) + " ", 1),
            _cache[0] || (_cache[0] = _createElementVNode("label", { class: "prix" }, "20.00 $", -1))
          ]))
        : _createCommentVNode("", true),
      (_ctx.panier.gibier)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.$t('boite.gibier')) + " ", 1),
            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "prix" }, "0.00 $", -1))
          ]))
        : _createCommentVNode("", true),
      (_ctx.panier.saumon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.$t('boite.saumon')) + " ", 1),
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "prix" }, "0,00 $", -1))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("label", { class: "total-label" }, "Total", -1)),
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.total), 1)
      ])
    ])
  ]))
}