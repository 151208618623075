import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/img/icon-saumon.svg'


const _hoisted_1 = { class: "contenu" }
const _hoisted_2 = { class: "recherche-et-bouton" }
const _hoisted_3 = { class: "barre-de-recherche" }
const _hoisted_4 = {
  class: "datatable",
  id: "table"
}
const _hoisted_5 = { class: "datatable-row-cell" }
const _hoisted_6 = { class: "datatable-row-cell-downloadcontainer" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "datatable-row-cell date-container" }
const _hoisted_11 = { class: "datatable-row-cell no-commande-container" }
const _hoisted_12 = { class: "datatable-row-cell coupon-container" }
const _hoisted_13 = { class: "datatable-row-cell nom-container" }
const _hoisted_14 = { class: "datatable-row-cell no-membre-container" }
const _hoisted_15 = { class: "datatable-row-cell adresse-container" }
const _hoisted_16 = { class: "datatable-row-cell no-telephone-container" }
const _hoisted_17 = { class: "datatable-row-cell mobile-container" }
const _hoisted_18 = { class: "datatable-row-cell modifier-container" }
const _hoisted_19 = { class: "footer-table" }
const _hoisted_20 = { class: "footer-table-content" }
const _hoisted_21 = { class: "pagination footer-table-content" }
const _hoisted_22 = { class: "no-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "coupons-manuels" }, [
      _createElementVNode("div", { class: "distribution-communautaire" }, "DISTRIBUTION COMMUNAUTAIRE"),
      _createElementVNode("div", { id: "ligne-pale" }),
      _createElementVNode("div", { class: "titre" }, [
        _createElementVNode("h1", null, "Coupons créés manuellement")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "Rechercher par numéro de commande",
            name: "recherche",
            id: "barre-recherche",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textSearch) = $event))
          }, null, 512), [
            [_vModelText, _ctx.textSearch]
          ]),
          _createElementVNode("button", {
            class: "btn-recherche",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateCommandesDependingPagesAndSearch && _ctx.updateCommandesDependingPagesAndSearch(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'search'],
              class: "logo-recherche"
            })
          ])
        ]),
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toCreateCoupon && _ctx.toCreateCoupon(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'file-plus'],
            class: "logo-in-button"
          }),
          _cache[4] || (_cache[4] = _createTextVNode(" Créer un coupon "))
        ])
      ]),
      _cache[17] || (_cache[17] = _createElementVNode("span", null, [
        _createElementVNode("h2", null, "Résultats")
      ], -1)),
      _cache[18] || (_cache[18] = _createElementVNode("div", { id: "ligne-fonce" }, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"datatable-row\" data-v-4d4e212d><div class=\"datatable-row-header telechargement\" data-v-4d4e212d>Télécharger</div><div class=\"datatable-row-header date\" data-v-4d4e212d>Date</div><div class=\"datatable-row-header no-commande\" data-v-4d4e212d>Nº Commande</div><div class=\"datatable-row-header coupon\" data-v-4d4e212d>Coupon</div><div class=\"datatable-row-header nom\" data-v-4d4e212d>Nom</div><div class=\"datatable-row-header no-membre\" data-v-4d4e212d>Nº De Membre</div><div class=\"datatable-row-header adresse\" data-v-4d4e212d>Adresse</div><div class=\"datatable-row-header no-telephone\" data-v-4d4e212d>Téléphone</div><div class=\"datatable-row-header mobile\" data-v-4d4e212d>Cellulaire</div><div class=\"datatable-row-header modifier\" data-v-4d4e212d></div></div>", 1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.commandes, (commande) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "datatable-row not-th gris",
            key: commande
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "datatable-row-header telechargement mobile-header" }, " Télécharger ", -1)),
                (commande.type === 'poisson')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: _ctx.getUrlCoupon(commande),
                      download: "couponPoisson.pdf"
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', 'fish'],
                        class: _normalizeClass(["logo-download fish", { show: commande.path_icone }])
                      }, null, 8, ["class"])
                    ], 8, _hoisted_7))
                  : (commande.type === 'gibier')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: _ctx.getUrlCoupon(commande),
                        download: "couponViande.pdf"
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'deer'],
                          class: _normalizeClass(["logo-download deer", { show: commande.path_icone }])
                        }, null, 8, ["class"])
                      ], 8, _hoisted_8))
                    : (commande.type === 'saumon')
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 2,
                          href: _ctx.getUrlCoupon(commande),
                          download: "couponSaumon.pdf"
                        }, [
                          _createElementVNode("img", {
                            class: _normalizeClass(["logo-download saumon", { show: commande.path_icone }]),
                            src: _imports_0,
                            alt: "saumon"
                          }, null, 2)
                        ], 8, _hoisted_9))
                      : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "datatable-row-header date mobile-header" }, "Date", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.dateParserLong(commande.pivot.created_at)), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "datatable-row-header no-commande mobile-header" }, " Nº Commande ", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.padLeadingZeros(commande.commande_id)), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "datatable-row-header coupon mobile-header" }, "Coupon", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.padLeadingZeros(commande.id)), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "datatable-row-header nom mobile-header" }, "Nom", -1)),
              _createTextVNode(" " + _toDisplayString(commande.ssamtaUser.firstName) + " " + _toDisplayString(commande.ssamtaUser.lastName), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "datatable-row-header no-membre mobile-header" }, " Nº de Membre ", -1)),
              _createTextVNode(" " + _toDisplayString(commande.ssamtaUser.bandNumber), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "datatable-row-header adresse mobile-header" }, "Adresse", -1)),
              _createElementVNode("p", null, _toDisplayString(commande.ssamtaUser.address), 1),
              _createElementVNode("p", null, _toDisplayString(commande.ssamtaUser.city), 1),
              _createElementVNode("p", null, _toDisplayString(commande.ssamtaUser.postalCode), 1)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "datatable-row-header no-telephone mobile-header" }, " Téléphone ", -1)),
              _createTextVNode(" " + _toDisplayString(commande.ssamtaUser.telephone), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "datatable-row-header mobile mobile-header" }, " Cellulaire ", -1)),
              _createTextVNode(" " + _toDisplayString(commande.ssamtaUser.mobile), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_router_link, {
                class: "button",
                to: {
              name: 'CreationModificationCoupon',
              params: { id: commande.commande_id },
            }
              }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode(" Modifier ")
                ])),
                _: 2
              }, 1032, ["to"])
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("button", {
            class: "btn-rapport",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.exportCSV && _ctx.exportCSV(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: [_ctx.ico, 'file-export']
            }, null, 8, ["icon"]),
            _cache[16] || (_cache[16] = _createTextVNode(" Exporter en CSV "))
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("p", null, _toDisplayString(_ctx.tableSize) + " RÉSULTATS", 1),
          (_ctx.currentPage > 1)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: ['fas', 'caret-left'],
                class: "logo-fleche",
                onClick: _ctx.previousPage,
                id: "fleche-gauche"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_22, "Page " + _toDisplayString(_ctx.currentPage) + " de " + _toDisplayString(_ctx.totalPages), 1),
          (_ctx.currentPage + 1 <= _ctx.totalPages)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                icon: ['fas', 'caret-right'],
                class: "logo-fleche",
                onClick: _ctx.nextPage,
                id: "fleche-droite"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}