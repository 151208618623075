import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-dashboard" }
const _hoisted_2 = { class: "corpTableaudebord" }
const _hoisted_3 = {
  key: 0,
  class: "titleLink"
}
const _hoisted_4 = {
  key: 1,
  class: "moduleDash"
}
const _hoisted_5 = {
  key: 0,
  class: "info"
}
const _hoisted_6 = {
  key: 1,
  class: "moduleContainer"
}
const _hoisted_7 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divBanniere" }, [
      _createElementVNode("span", null, "Qey Qey !")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, "Qey Qey, " + _toDisplayString(_ctx.userName) + "!", 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listeDeLiens, (lien) => {
        return (_openBlock(), _createElementBlock("div", {
          key: lien.num
        }, [
          (lien.text.length > 0)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(lien.text), 1))
            : _createCommentVNode("", true),
          (lien.items.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lien.items, (item) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: item.num
                  }, [
                    (item.type === 'info')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t(item.text)), 1))
                      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          (item.type != 'title')
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                class: "module",
                                id: item.id,
                                to: item.to
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    style: {"max-height":"60px"},
                                    src: item.img.src,
                                    alt: item.img.alt
                                  }, null, 8, _hoisted_7),
                                  _createElementVNode("h2", null, _toDisplayString(item.text), 1)
                                ]),
                                _: 2
                              }, 1032, ["id", "to"]))
                            : _createCommentVNode("", true)
                        ]))
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}