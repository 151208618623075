import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "titre" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "content-box" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "total-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Requestbox = _resolveComponent("Requestbox")!
  const _component_Card = _resolveComponent("Card")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InformationFrom = _resolveComponent("InformationFrom")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TotalBox = _resolveComponent("TotalBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('confirmationCommande')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Card, {
          title: _ctx.$t('boitesDemandees')
        }, {
          content: _withCtx(() => [
            _createVNode(_component_Requestbox)
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_Card, {
          title: _ctx.$t('infosEnvoi')
        }, {
          content: _withCtx(() => [
            _createVNode(_component_router_link, { to: { name: 'profil' } }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('consigneInfosProfil')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_InformationFrom, {
              class: "informationFrom",
              distribution: _ctx.distribution,
              "onUpdate:distribution": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.distribution) = $event)),
              onInterface: _ctx.getInfoFormInterface
            }, null, 8, ["distribution", "onInterface"])
          ]),
          _: 1
        }, 8, ["title"]),
        _withDirectives(_createVNode(_component_Card, {
          title: _ctx.$t('methodePaiement')
        }, {
          content: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("form", { id: "payment-form" }, [
              _createElementVNode("div", { id: "card-container" })
            ], -1)
          ])),
          _: 1
        }, 8, ["title"]), [
          [_vShow, _ctx.totalPrice > 0]
        ]),
        (_ctx.errorCommand)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          disabled: _ctx.loading,
          class: "btn-demande",
          ref: "cardButton"
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'box-circle-check'] }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('boutons.passerCommande')), 1)
        ], 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(), _createBlock(_component_TotalBox, {
          key: _ctx.totalPrice,
          totalPrice: _ctx.totalPrice
        }, null, 8, ["totalPrice"]))
      ])
    ])
  ], 64))
}