import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cherch-btn" }
const _hoisted_2 = { class: "liste-permis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "Rechercher par nom, téléphone…",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
          }, null, 512), [
            [_vModelText, _ctx.search]
          ]),
          _createElementVNode("button", {
            class: "recherche",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.filter && _ctx.filter(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: [_ctx.ico, 'magnifying-glass']
            }, null, 8, ["icon"])
          ])
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "result" }, "Résultats", -1)),
        _createVNode(_component_DataTable, {
          selection: _ctx.selectedOrder,
          "onUpdate:selection": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedOrder) = $event)),
          value: _ctx.rapports,
          paginator: true,
          rows: 10,
          ref: "dt",
          paginatorTemplate: "CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          rowsPerPageOptions: [10, 20, 50],
          responsiveLayout: "scroll",
          currentPageReportTemplate: "{totalRecords} PERMIS"
        }, {
          paginatorstart: _withCtx(() => [
            _createElementVNode("button", {
              class: "btn-rapport",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.exportPdf && _ctx.exportPdf(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: [_ctx.ico, 'file-pdf']
              }, null, 8, ["icon"]),
              _cache[5] || (_cache[5] = _createTextVNode(" Exporter en PDF "))
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              header: "DATE",
              sortable: true
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.dateParserLong(data.created_at)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              header: "NUMÉRO DE REÇU",
              field: "squareReceiptNumber"
            }),
            _createVNode(_component_Column, {
              field: "id",
              header: "Nº COMMANDE",
              sortable: true
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.padLeadingZeros(data.id)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              header: "NOM",
              sortable: true
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.firstName) + " ", 1),
                _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(data.lastName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              header: "NO. CERTIFICAT",
              field: "noCertificat",
              sortable: true
            }),
            _createVNode(_component_Column, {
              header: "NO. MEMBRE",
              field: "bandNumber",
              sortable: true
            }),
            _createVNode(_component_Column, {
              header: "DATE DE NAISSANCE",
              sortable: true
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.dateParserLong(data.birthDate)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "PERMIS" }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("ul", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.commandes_permis_items, (permis) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: permis.id
                    }, _toDisplayString(permis.nom), 1))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              header: "ADRESSE",
              field: "adress"
            }),
            _createVNode(_component_Column, {
              header: "TÉLÉPHONE",
              field: "telephone"
            }),
            _createVNode(_component_Column, {
              header: "COURRIEL",
              field: "email"
            })
          ]),
          _: 1
        }, 8, ["selection", "value"])
      ]),
      _: 1
    })
  ]))
}