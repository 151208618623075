import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container-box" }
const _hoisted_3 = {
  key: 0,
  class: "validation-success"
}
const _hoisted_4 = {
  key: 1,
  class: "validation-failure"
}
const _hoisted_5 = {
  key: 2,
  class: "validation-pending"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_qrcode_stream = _resolveComponent("qrcode-stream")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_qrcode_stream, {
        camera: _ctx.camera,
        track: _ctx.paintOutline,
        onDecode: _ctx.onDecode,
        onInit: _ctx.onInit
      }, {
        default: _withCtx(() => [
          (_ctx.validationSuccess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_font_awesome_icon, {
                  id: "qrSuccess",
                  icon: ['fal', 'circle-check'],
                  class: "ico",
                  width: "150px"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.success) + " ", 1),
                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" Distribution des boîtes : " + _toDisplayString(_ctx.nbBoitesDistribuees) + "/" + _toDisplayString(_ctx.nbBoitesCommandees), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.validationFailure)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_font_awesome_icon, {
                  id: "qrError",
                  icon: ['fal', 'circle-xmark'],
                  class: "ico",
                  width: "150px"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.error), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.validationPending)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Réclamation de la commande en cours... "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["camera", "track", "onDecode", "onInit"])
    ])
  ]))
}