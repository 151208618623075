import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/img/icon-saumon.svg'


const _hoisted_1 = { class: "content-boite" }
const _hoisted_2 = {
  key: 0,
  class: "box b1"
}
const _hoisted_3 = { class: "boite" }
const _hoisted_4 = {
  key: 1,
  class: "box"
}
const _hoisted_5 = { class: "boite" }
const _hoisted_6 = { class: "content-boite" }
const _hoisted_7 = {
  key: 0,
  class: "box b1"
}
const _hoisted_8 = { class: "boite" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.panier.poisson)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, {
                icon: "fish",
                class: "ico fish"
              }),
              _createElementVNode("span", null, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('boite.poisson')), 1)
              ])
            ]),
            _createElementVNode("button", {
              class: "btn-supprimer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteBoite(1)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'circle-xmark'],
                class: "ico"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('boutons.supprimer')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.panier.gibier)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, {
                icon: "deer",
                class: "ico gibier"
              }),
              _createElementVNode("span", null, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('boite.gibier')), 1)
              ])
            ]),
            _createElementVNode("button", {
              class: "btn-supprimer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteBoite(2)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'circle-xmark'],
                class: "ico"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('boutons.supprimer')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.panier.saumon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[3] || (_cache[3] = _createElementVNode("img", {
                class: "saumon-icon",
                src: _imports_0,
                alt: "saumon"
              }, null, -1)),
              _createElementVNode("span", null, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('boite.saumon')), 1)
              ])
            ]),
            _createElementVNode("button", {
              class: "btn-supprimer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteBoite(3)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'circle-xmark'],
                class: "ico"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('boutons.supprimer')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}