<template>
  <div class="container-box">
    <div class="header-box">
      <span class="span-title">Total</span>
    </div>
    <div class="content-box">
      <div v-if="panier.length > 0">
        <div class="permis-row" v-for="permis in panier" v-bind:key="permis.id">
          <span v-if="locale == 'fr'" class="nom-permis">{{ permis.nom }}</span>
          <span v-else class="nom-permis">{{ permis.nom_en }}</span>
          <label class="prix">{{ permis.prix.toString() + ' $' }}</label>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <div>
        <label class="total-label">Total</label>
        <label class="prix">{{ total }}</label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Panier } from '@/types/Panier';
import EventBus from '@/events/EventBus';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'TotalBox',
  data() {
    return {
      panier: [],
      total: '0,00 $' as string | undefined,
      locale: (this.$i18n as any).locale,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
  },
  props: {
    totalPrice: Number,
    cart: Array,
  },
  created() {
    this.panier = this.$props.cart;
    this.total = this.$props.totalPrice?.toFixed(2).toString() + ' $';
  },
});
</script>

<style scoped lang="scss">
.container-box {
  padding: 0px;
  margin-top: 25px;
  background-color: $colorBlanc;
  border-radius: 5px;
  border: 2px solid $bleuFonce;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  text-align: start;
  .header-box {
    color: $bleuFonce;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $grisFonce;
    display: flex;
    .span-title {
      font-size: 17px;
      font-weight: 700;
      align-self: center;
      color: $bleuFonce;
    }
  }
  .content-box {
    div {
      font-weight: 500;
      padding: 10px;
      .prix {
        //float: right;
        font-weight: bold;
      }
      .nom-permis {
        max-width: 70%;
      }
      .permis-row {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .footer-box {
    border-top: 1px solid $grisFonce;
    padding-top: 40px;
    div {
      display: flex;
      .total-label {
        align-self: center;
        color: $bleuFonce;
        font-weight: bold;
        font-size: 18px;
      }
      .prix {
        margin-left: auto;
        align-self: center;
        font-weight: bold;
        color: $bleu;
        font-size: 26px;
      }
    }
  }
}
@media only screen and (max-width: $md) {
  .container-box {
    margin-top: 75px;
  }
}
</style>
