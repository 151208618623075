import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/footer.png'


const _hoisted_1 = { class: "container-footer" }
const _hoisted_2 = { class: "divFooter" }
const _hoisted_3 = {
  class: "elementFooterHaut",
  id: "elementFooterHautGauche"
}
const _hoisted_4 = { class: "barreBlancheFooter" }
const _hoisted_5 = { class: "elementFooter" }
const _hoisted_6 = { class: "admin" }
const _hoisted_7 = { class: "elementFooter" }
const _hoisted_8 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('besoinAide')), 1),
        _createElementVNode("h2", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('contact')) + " ", 1),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "lienTel" }, [
            _createElementVNode("a", { href: "tel:+118883992393" }, [
              _createElementVNode("br", { class: "br" }),
              _createTextVNode("1 888 399-2393")
            ])
          ], -1))
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "elementFooterHaut",
        id: "elementFooterHautDroit"
      }, [
        _createElementVNode("div", { class: "img-container" }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "logoFooter",
            alt: "logo malecitesFooter"
          })
        ])
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, [
          _createVNode(_component_router_link, { to: { name: 'LoginAdmin' } }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('administration')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, [
          _createTextVNode(_toDisplayString(_ctx.$t('copyright')) + " ", 1),
          _cache[2] || (_cache[2] = _createElementVNode("a", { href: "https://conceptionwm.com/" }, "Conception WebMédia.", -1))
        ])
      ])
    ])
  ]))
}