import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-message" }
const _hoisted_2 = { class: "sous-titre" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass('box-message ' + _ctx.type)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass('titre ' + _ctx.type)
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fal', _ctx.icon],
          class: "ico"
        }, null, 8, ["icon"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.HeaderMessage), 1)
      ], 2),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.BodyMessage), 1)
    ], 2)
  ]))
}