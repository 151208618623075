import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/img/icon-saumon.svg'


const _hoisted_1 = { class: "cherch-btn" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "lieu"
}
const _hoisted_4 = {
  key: 1,
  class: "lieu"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "success"
}
const _hoisted_8 = {
  key: 1,
  class: "error"
}
const _hoisted_9 = {
  key: 0,
  class: "success"
}
const _hoisted_10 = {
  key: 1,
  class: "error"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  id: "btn-distribuer",
  class: "button button-primary",
  disabled: ""
}
const _hoisted_13 = {
  key: 0,
  style: {"color":"white"}
}
const _hoisted_14 = {
  key: 1,
  style: {"color":"white"}
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 0,
  id: "btn-distribuer",
  class: "button button-primary",
  disabled: ""
}
const _hoisted_17 = {
  key: 0,
  style: {"color":"white"}
}
const _hoisted_18 = {
  key: 1,
  style: {"color":"white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "Rechercher par nom, numéro commande...",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
          }, null, 512), [
            [_vModelText, _ctx.search]
          ]),
          _createElementVNode("button", {
            class: "recherche",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.filter && _ctx.filter(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: [_ctx.ico, 'magnifying-glass']
            }, null, 8, ["icon"])
          ])
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("p", { class: "sites" }, "Site de distribution", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.site) = $event)),
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterDatatable())),
          placeholder: "Tous"
        }, [
          _cache[10] || (_cache[10] = _createElementVNode("option", { value: "-1" }, "Tous", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSiteDistributionByUser, (adress) => {
            return (_openBlock(), _createElementBlock("option", {
              key: adress.ville,
              value: adress.id
            }, _toDisplayString(_ctx.uppercase(adress.ville)) + " - " + _toDisplayString(adress.lieux), 9, _hoisted_2))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.site]
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("p", { class: "sites" }, "Statut de distribution", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.statut) = $event)),
          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filterDatatable())),
          placeholder: "Tous"
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("option", { value: -1 }, "Tous", -1),
          _createElementVNode("option", { value: 1 }, "Distribuée", -1),
          _createElementVNode("option", { value: 0 }, "Non distribuée", -1)
        ]), 544), [
          [_vModelSelect, _ctx.statut]
        ]),
        (!_ctx.isAdmin)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.siteNom), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_4, "Rapport des sites au complet")),
        _createVNode(_component_DataTable, {
          selection: _ctx.selectedOrder,
          "onUpdate:selection": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedOrder) = $event)),
          selectionMode: "single",
          value: _ctx.rappots,
          paginator: true,
          rows: 10,
          ref: "dt",
          paginatorTemplate: "CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          rowsPerPageOptions: [10, 20, 50],
          responsiveLayout: "scroll",
          currentPageReportTemplate: "{totalRecords} COMMANDES"
        }, {
          paginatorstart: _withCtx(() => [
            (_ctx.isAdmin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("button", {
                    class: "btn-rapport",
                    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.exportCSV && _ctx.exportCSV(...args)))
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: [_ctx.ico, 'file-export']
                    }, null, 8, ["icon"]),
                    _cache[13] || (_cache[13] = _createTextVNode(" Exporter en CSV "))
                  ]),
                  (_ctx.selectedOrder === null)
                    ? (_openBlock(), _createElementBlock("button", _hoisted_12, " Distribuer "))
                    : _createCommentVNode("", true),
                  (_ctx.selectedOrder != null)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        id: "btn-distribuer",
                        class: "button button-primary",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.distribuerCommande()))
                      }, [
                        (_ctx.getBooleanDistribuee())
                          ? (_openBlock(), _createElementBlock("p", _hoisted_13, " Annuler la distribution "))
                          : (_openBlock(), _createElementBlock("p", _hoisted_14, "Distribuer"))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (_ctx.selectedOrder === null)
                    ? (_openBlock(), _createElementBlock("button", _hoisted_16, " Distribuer "))
                    : _createCommentVNode("", true),
                  (_ctx.selectedOrder != null)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        id: "btn-distribuer",
                        class: "button button-primary",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.distribuerCommande()))
                      }, [
                        (_ctx.getBooleanDistribuee())
                          ? (_openBlock(), _createElementBlock("p", _hoisted_17, " Annuler la distribution "))
                          : (_openBlock(), _createElementBlock("p", _hoisted_18, "Distribuer"))
                      ]))
                    : _createCommentVNode("", true)
                ]))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, { header: "DATE" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.dateParserLong(data.created_at)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "id",
              header: "Nº COMMANDE"
            }, {
              body: _withCtx(({ data }) => [
                (_ctx.isAdmin)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.padLeadingZeros(data.pivot.commande_id)), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.padLeadingZeros(data.commande_id)), 1))
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "NOM" }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.ssamtaUser.firstName) + " ", 1),
                _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(data.ssamtaUser.lastName), 1)
              ]),
              _: 1
            }),
            (_ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  header: "BOITE"
                }, {
                  body: _withCtx(({ data }) => [
                    (data.type === 'poisson')
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 0,
                          icon: "fish",
                          class: _normalizeClass(["ico", { showIcon: data.path_icone }])
                        }, null, 8, ["class"]))
                      : (data.type === 'gibier')
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 1,
                            icon: "deer",
                            class: _normalizeClass(["ico gibier", { showIcon: data.path_icone }])
                          }, null, 8, ["class"]))
                        : (data.type === 'saumon')
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 2,
                              class: _normalizeClass(["saumon-icon", { showIcon: data.path_icone }]),
                              src: _imports_0,
                              alt: "saumon"
                            }, null, 2))
                          : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_Column, {
                  key: 1,
                  header: "BOITE"
                }, {
                  body: _withCtx(({ data }) => [
                    (data.produit.nom === 'Boîte de poisson')
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 0,
                          icon: "fish",
                          class: _normalizeClass(["ico", { showIcon: data.produit.path_icone }])
                        }, null, 8, ["class"]))
                      : (data.produit.nom === 'Boîte de gibier')
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 1,
                            icon: "deer",
                            class: _normalizeClass(["ico gibier", { showIcon: data.produit.path_icone }])
                          }, null, 8, ["class"]))
                        : (data.produit.nom === 'Boîte de saumon')
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 2,
                              class: _normalizeClass(["saumon-icon", { showIcon: data.produit.path_icone }]),
                              src: _imports_0,
                              alt: "saumon"
                            }, null, 2))
                          : _createCommentVNode("", true)
                  ]),
                  _: 1
                })),
            (_ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 2,
                  header: "DISTRIBUÉE"
                }, {
                  body: _withCtx(({ data }) => [
                    (data.pivot.distribuee)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fal', 'circle-check'],
                            class: "ico-distribuee success"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (!data.pivot.distribuee)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fal', 'circle-xmark'],
                            class: "ico-distribuee error"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_Column, {
                  key: 3,
                  header: "DISTRIBUÉE"
                }, {
                  body: _withCtx(({ data }) => [
                    (data.distribuee)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fal', 'circle-check'],
                            class: "ico-distribuee success"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (!data.distribuee)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fal', 'circle-xmark'],
                            class: "ico-distribuee error"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["selection", "value"])
      ]),
      _: 1
    })
  ]))
}